import React, { useState, useEffect } from "react";
import { ActivityIndicator, View, FlatList, useWindowDimensions, Image, Text } from 'react-native';
import Lottie from "react-lottie";
import PepePoop from "./17081-pepe-poo-poo.json";
import HiddenButton from "./HiddenButton";

function PictureFeed(){

    const _maxImageWidth = 900;
    const _continuationTokenLocalStorageKey = "ContinuationToken";
    const _newFeedPathname = "/new";

    const windowDimensions = useWindowDimensions();
    const [pictures, setPictures] = useState([{ Id: 'header' }]);
    const [fetchingPictures, setFetchingPictures] = useState(false);
    const [continuationToken, setContinuationToken] = useState(null);
    const [pictureWidth, setPictureWidth] = useState(windowDimensions.width);
    const [windowHeight, setWindowHeight] = useState(windowDimensions.height);
    const [windowWidth, setWindowWidth] = useState(windowDimensions.width);

    //fetch first page on launch
    useEffect(() => {

        //try and get a continuation token from local storage
        let ct = null;

        if (window.location.pathname === _newFeedPathname)
        {
            updateContinuationToken(null)
        } 
        else {
            try {

                ct = localStorage.getItem(_continuationTokenLocalStorageKey);
            } catch(e) {
                console.log(e);
            }
        }

        fetchPictures(ct);
    }, []);

    //update picture dimensions on window resize
    useEffect(() => {
        setPictureWidth(windowDimensions.width > _maxImageWidth ? _maxImageWidth : windowDimensions.width);
        setWindowHeight(windowDimensions.height);
        setWindowWidth(windowDimensions.width);
    }, [windowDimensions.width, windowDimensions.height]);

    //preload image urls 
    const preloadPictures = (urls) => {
        let tasks = [];

        urls.forEach(i => {
            if (i) {
                tasks.push(Image.prefetch(i));
            }
        });

        Promise
            .all(tasks)
            .catch((i) => {
                console.log(i);
            });
    }

    //set continuation token locally and in browser storage
    const updateContinuationToken = (ct) => {
        
        setContinuationToken(ct);

        try {
            localStorage.setItem(_continuationTokenLocalStorageKey, ct);
        } catch(e) {
            console.log(e);
        }
    }

    //fetch the next set of pictures using a token
    const fetchPictures = async (ct) => {

        setFetchingPictures(true);

        const pictureResponse = await fetch(`${process.env.REACT_APP_API_HOST_ORIGIN}/api/GetPictures?token=${ct}&new=${window.location.pathname === _newFeedPathname}`)
            .then((res) => res.json())
            .catch((e) => console.log(e));

        setPictures([...pictures, ...(pictureResponse?.Pictures ?? [])]);

        updateContinuationToken(pictureResponse?.ContinuationToken ?? null);

        setFetchingPictures(false);

        preloadPictures(pictureResponse?.Pictures.map(i => i.Url) ?? []);
    };

    return(
        <View style={{
            flex: 1,
            width: continuationToken === null || pictures.length <= 1 ? _maxImageWidth : windowWidth,
            backgroundColor: continuationToken === null || pictures.length <= 1 ? '#202020' : '#181818'
        }}>
            {
                continuationToken === null || pictures.length <= 1
                    ?
                        <View style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <ActivityIndicator size="large" color="#787878" />
                        </View>
                    :
                        <>
                            <FlatList
                                style={{
                                    height: windowHeight
                                }}
                                data={pictures}
                                keyExtractor={(i) => i.Id}
                                onEndReached={() => {
                                    if (!fetchingPictures) {
                                        fetchPictures(continuationToken);
                                    }
                                }}
                                showsVerticalScrollIndicator={false}
                                showsHorizontalScrollIndicator={false}
                                onEndReachedThreshold={20}
                                renderItem={(i) => {
                                    if (i.item.Id === 'header'){
                                        return (
                                            <View style={{
                                                padding: 10,
                                                paddingTop: 50,
                                                paddingBottom: 50,
                                                flexDirection: 'row',
                                                maxHeight: 170,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                overflow: 'hidden'
                                            }}>
                                                <View style={{
                                                    flex: 1,
                                                    paddingRight: 10,
                                                    justifyContent: 'center'
                                                }}>
                                                    <Text style={{
                                                        color: '#bdbdbd',
                                                        fontFamily: 'Trebuchet MS',
                                                        fontSize: 12,
                                                        textAlign: 'right',
                                                        textTransform: 'uppercase',
                                                        letterSpacing: 1
                                                    }}
                                                    >Picture</Text>
                                                    <Text style={{
                                                        color: '#bdbdbd',
                                                        fontWeight: 'bold',
                                                        fontFamily: 'Trebuchet MS',
                                                        fontSize: 20,
                                                        textAlign: 'right',
                                                        textTransform: 'uppercase',
                                                        letterSpacing: 1
                                                    }}
                                                    >Poofect</Text>
                                                </View>
                                                <View style={{
                                                    flex: 1
                                                }}>
                                                    <View
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            cursor: 'default'
                                                        }}
                                                    >
                                                        <Lottie
                                                            height={80}
                                                            width={80}
                                                            options={{
                                                                loop: true,
                                                                autoplay: true,
                                                                animationData: PepePoop,
                                                                rendererSettings: {
                                                                    preserveAspectRatio: "xMidYMid slice"
                                                                }
                                                            }}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        )
                                    }
                                    return (
                                        <View style={{
                                            alignItems: 'center',
                                            width: windowWidth,
                                            marginBottom: 15
                                        }}>
                                            <Image
                                                style={{
                                                    width: pictureWidth,
                                                    height: i.item.Height / (i.item.Width/pictureWidth),
                                                    resizeMode: 'cover',
                                                    backgroundColor: '#202020'
                                                }}
                                                source={{
                                                    uri: i.item.Url,
                                                }}
                                            />
                                            <HiddenButton
                                                style={{
                                                    width: 30,
                                                    height: 30,
                                                    position: 'absolute',
                                                    left: (windowWidth - pictureWidth) / 2
                                                }}
                                                pushCount={6}
                                                onAction={() => {
                                                    async function reportPicture() {
                                                        await fetch(`${process.env.REACT_APP_API_HOST_ORIGIN}/api/RegisterComplaint?picture=${i.item.Id}&token=${continuationToken}`)
                                                            .catch((e) => console.log(e));
                                                    };
                                                    reportPicture();
                                                }}
                                            />
                                        </View>
                                    )
                                }}
                            /> 
                        </>
            }
            <HiddenButton
                style={{
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    left: 0
                }}
                pushCount={6}
                onAction={() => {
                    updateContinuationToken(null);
                    window.location.reload();
                }}
            />
        </View>
    )
}

export default PictureFeed;