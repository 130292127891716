import React, { useState, useEffect } from "react";
import { TouchableOpacity } from 'react-native';

function HiddenButton(props){

    const [counter, setCounter] = useState(0);
    const [actioned, setActioned] = useState(false);

    useEffect(() => {
        if (counter >= props.pushCount && !actioned){
            props.onAction();
            setActioned(true);
        }
    },[counter])

    return (
        <TouchableOpacity 
            style={[
                {
                    cursor: 'default'
                },
                props?.style
            ]}
            onPress={() => setCounter(counter + 1)}
        />
    )
}

export default HiddenButton;