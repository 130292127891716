import React from "react";
import { View } from 'react-native';
import PictureFeed from './PictureFeed';

function App() {
  return (
    <View style={{
      flex: 1,
      backgroundColor: '#181818',
      alignItems: 'center',
      webkitUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    }}>
      <PictureFeed />
    </View>
  );
}

export default App;